import { HISTORY_IMGIX } from '../utils/variables';
import { createCORSRequest } from './helpers';
import { getFileExension, getFileName } from '../utils/fileOperations';
import { flipCanvasHorizontally, flipCanvasVertically } from '@smartmockups/rendering';
import { historyService } from './ServiceClient';

export const HISTORY_THUMB_WIDTH = 768;
export const HISTORY_THUMB_FORMAT = 'jpg';
const HISTORY_ENDPOINT = 'history';
const CONTINUATION_VAR = 'continuation';

export async function getHistoryApi(timestamp, username) {
    const res = await historyService.get(`${HISTORY_ENDPOINT}/${username}/${timestamp}`);

    console.log('%c HISTORY', 'background-color: lightblue', res.screens);
    const promises = [
        ...res.screens.map((screen) => {
            return loadScreen(screen);
        })
    ];

    res.screens = await Promise.all(promises);
    return res;
}

export async function fetchHistoryListApi(username, pagination) {
    let url = `historylist/${username}`;
    if (pagination) {
        url += `?${CONTINUATION_VAR}=${pagination}`;
    }
    return historyService.get(url);
}

export async function deleteHistoryApi(timestamp, username) {
    return historyService.delete(`${HISTORY_ENDPOINT}/${username}/${timestamp}`);
}

/**
 * Calls the API to duplicate history item
 * @param {number} timestamp
 * @param {string} username
 * @returns {HistoryItem}
 */
export async function duplicateHistoryItemApi(timestamp, username) {
    const url = `${HISTORY_ENDPOINT}/duplicate`;
    const data = {
        username,
        timestamp
    };
    return historyService.post(url, data);
}

export const uploadHistoryImage = async (previewImg, timestamp, username) => {
    const filename = `${timestamp}`;
    const s3 = await getSignedUrl(filename, HISTORY_THUMB_FORMAT, username, '_pr');
    await uploadBlobToS3(previewImg, s3.mime, s3.url);

    return s3;
};

const getHistoryScreens = (screensArg) => {
    const screens = screensArg.map((scr) => {
        if (!scr) return;
        const { uploadedImage, ...screen } = scr;

        // remove video item in case it was not uploaded ( should not happen)
        if (
            screen.uploadedVideo &&
            typeof screen.uploadedVideo !== 'string' &&
            !(screen.uploadedVideo instanceof String)
        ) {
            const { uploadedVideo, ...output } = screen;
            return output;
        }
        return screen;
    });

    return screens;
};

export const addHistoryApi = async (data, allowEmpty) => {
    const dataScreens = data.screens || [];
    const uploadedScreensCounter = data.screens.filter((s) => s?.historyFilename).length;

    // we don't want to upload empty history items without user's screen
    if (!allowEmpty && uploadedScreensCounter === 0) return null;
    const historyScreens = getHistoryScreens(dataScreens);

    try {
        const bg = await uploadBgImage(data.bg, data.timestamp, data.username);
        const finalData = { ...data, screens: historyScreens, bg };

        console.log(`%c finalData`, 'background-color: pink; color: white', finalData);
        return historyService.post(HISTORY_ENDPOINT, { ...finalData });
    } catch (e) {
        console.error('Unable to updateHistory', e);
    }
};

export async function uploadHistoryScreensApi(screens, username) {
    try {
        const promises = [
            ...screens.map((screen) => {
                return uploadScreen(screen, username);
            })
        ];

        return await Promise.all(promises);
    } catch (err) {
        console.log(err);
    }
}

async function uploadScreen(screen, username) {
    try {
        if (!screen || !screen.uploadedImage || screen.historyFilename) return null;

        const filetype = 'png';
        const filename = getFileName(screen.uploadedImageTitle);
        let res = await getSignedUrl(filename, filetype, username);
        let s3 = res;

        const blob = await canvasToBlob(screen.uploadedImage, s3.mime);

        await uploadBlobToS3(blob, s3.mime, s3.url);

        const historyScreen = { historyFilename: s3.key };

        // in case screen has a video, and it's not an URL, we have to upload it as well
        if (
            screen.uploadedVideo &&
            typeof screen.uploadedVideo !== 'string' &&
            !(screen.uploadedVideo instanceof String)
        ) {
            res = await getSignedUrl(
                screen.uploadedImageTitle,
                getFileExension(screen.uploadedImageTitle),
                username
            );
            s3 = res;
            await uploadBlobToS3(screen.uploadedVideo, s3.mime, s3.url);

            historyScreen.uploadedVideo = s3.fileUrl;
        }

        return historyScreen;
    } catch (err) {
        console.log(err);
    }
}

function getSignedUrl(filename, filetype, username, postfix) {
    return historyService.get(
        `upload?filetype=${filetype}&filename=${filename}&username=${username}&postfix=${
            postfix || ''
        }`
    );
}

function getImageUrlAsCanvas(image) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const canvas = document.createElement('canvas');
            // maximalni sirku nahravaneho obrazku omezime na 2500k at to neni zbytecne mega velky
            canvas.width = Math.min(img.width, 2500);
            canvas.height = Math.round((img.height / img.width) * canvas.width);
            const ctx = canvas.getContext('2d');
            ctx.globalCompositeOperation = 'copy';
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'source-over';

            resolve(canvas);
        };
        img.onerror = () => {
            reject();
        };
        img.crossOrigin = 'Anonymous';
        img.src = image;
    });
}
function imageToCanvas(image) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas;
}

async function canvasToBlob(canvas, mime) {
    return new Promise((resolve) => {
        canvas.toBlob(
            (blob) => {
                resolve(blob);
            },
            mime,
            0.9
        );
    });
}

// TODO refactor this into reusable shared function
//  find other occurences and unite
function uploadBlobToS3(blob, mime, s3url) {
    return new Promise((resolve, reject) => {
        const xhr = createCORSRequest('PUT', s3url);

        xhr.onload = () => {
            if (xhr.status === 200) {
                console.log(xhr.status);

                resolve();
            } else {
                console.log(xhr.status);

                reject(xhr.status);
            }
        };

        xhr.setRequestHeader('Content-Type', mime);
        xhr.setRequestHeader('x-amz-acl', 'private');
        xhr.send(blob);
    });
}

export async function loadScreen(screen) {
    if (!screen || !screen.historyFilename) return null;
    const url = `${HISTORY_IMGIX}/${screen.historyFilename}?max-w=1920&auto=compress,format`;
    try {
        const newScreen = { ...screen };
        // create uploadedImage canvas from the history file
        let uploadedImage = await getImageUrlAsCanvas(url);

        // backwards compatibility with old history screens, where transform was not stored
        if (screen.hasOwnProperty('transform')) {
            // handle transformations upon the uploadedImage
            // TODO can remove this logic as this is done in screenCreator itself
            // if (screen.transform.rotate && screen.transform.rotate !== 0) {
            //     uploadedImage = rotateCanvas(uploadedImage, screen.transform.rotate);
            // }
            if (screen.transform.hflip) {
                uploadedImage = flipCanvasHorizontally(uploadedImage);
            }
            if (screen.transform.vflip) {
                uploadedImage = flipCanvasVertically(uploadedImage);
            }
        }

        newScreen.uploadedImage = uploadedImage;
        return newScreen;
    } catch (err) {
        return null;
    }
}

async function uploadBgImage(bg, timestamp, username) {
    if (!bg || Array.isArray(bg) || bg.source !== 'local') return bg;
    try {
        const finalData = { ...bg };
        const filename = `${timestamp}_bg`;
        const res = await getSignedUrl(filename, 'png', username);
        const s3 = res.data;
        const canvas = imageToCanvas(bg.image);
        const blob = await canvasToBlob(canvas, s3.mime);
        await uploadBlobToS3(blob, s3.mime, s3.url);
        finalData.image = s3.key;
        finalData.source = 'history';

        return finalData;
    } catch (err) {
        alert(err);
    }
}
