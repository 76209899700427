import { deleteHistoryApi, duplicateHistoryItemApi, fetchHistoryListApi } from '../apis/historyApi';
import {
    FETCH_MOCKUPS_FOR_CATEGORY,
    SET_ACTIVE_TAGS,
    SET_ORDER,
    SET_LIVE_PREVIEW,
    GET_SEARCH_RESULTS,
    EMPTY_SEARCH_RESULTS,
    FETCH_HISTORY_LIST,
    FETCH_HISTORY_LIST_PAGE,
    DELETE_HISTORY,
    DUPLICATE_HISTORY,
    SET_LAST_VISITED_COLLECTION,
    FETCH_MOCKUPS_FOR_BUNDLE
} from './actionTypes';
import serviceClient, { mockupsService } from '../apis/ServiceClient';
import { BUNDLE_MOCKUPS_API } from '../utils/variables';
import getLocaleCodeLong from "../utils/locale/getLocaleCodeLong";

const algoliasearch = require('algoliasearch');

const client = algoliasearch('CHOYNIXSLK', '137a0fb339d1422645b68c2d1eaafd20');
const index = client.initIndex('mockups');

export function setActiveTags(tags) {
    return {
        type: SET_ACTIVE_TAGS,
        payload: tags
    };
}

export function setOrder(order) {
    return {
        type: SET_ORDER,
        payload: order
    };
}

export function setLivePreview(livePreview) {
    return {
        type: SET_LIVE_PREVIEW,
        payload: livePreview
    };
}

export function getSearchResults(query, locale) {
    query = query.replace(/(<([^>]+)>)/ig, '');

    if (!query) {
        return {
            type: EMPTY_SEARCH_RESULTS,
            payload: []
        };
    }

    const restrictSearchableAttributes = [
        'title',
        'tags',
        'previewTitle',
        'variation_title',
        'subcategory',
        'category',
        'predictedTags'
    ];
    const attributesToRetrieve = [
        'id',
        'shortId',
        'title',
        'isPremium',
        'category',
        'subcategory',
        'publishDate',
        'hasBackground',
        'isDraft',
        'tags',
        'previewTitle',
        'thumb',
        'blurhash',
        'width',
        'height',
        'type',
        'layers',
        'metadata',
        'previews'
    ];

    if (locale && locale !== 'en') {
        restrictSearchableAttributes.unshift(`title-${locale}`);
    }

    const request = index.search(query, {
        filters: 'NOT disabled.smartmockups: true',
        attributesToRetrieve,
        restrictSearchableAttributes,
        hitsPerPage: 5000
    });

    return {
        type: GET_SEARCH_RESULTS,
        payload: request
    };
}

export function fetchHistoryList(username) {
    const request = fetchHistoryListApi(username);
    return {
        type: FETCH_HISTORY_LIST,
        payload: request
    };
}
export function fetchHistoryListPage(username, pagination) {
    const request = fetchHistoryListApi(username, pagination);
    return {
        type: FETCH_HISTORY_LIST_PAGE,
        payload: request
    };
}

export function duplicateHistory(username, pagination) {
    const request = duplicateHistoryItemApi(username, pagination);
    return {
        type: DUPLICATE_HISTORY,
        payload: request
    };
}

export function deleteHistory(timestamp, username) {
    const request = deleteHistoryApi(timestamp, username);
    return {
        type: DELETE_HISTORY,
        payload: request
    };
}

export function fetchMockupsForCategory(category, user, locale) {
    let qs = '';
    if (user && user.isScheduled) {
        qs = `&scheduled=true`;
    }
    if (user && user.isAdmin) {
        qs = `&scheduled=true&drafts=true&nocache=true`;
    }
    const request = mockupsService.get(`collection/${category}?locale=${getLocaleCodeLong(locale)}${qs}`);
    return {
        type: FETCH_MOCKUPS_FOR_CATEGORY,
        payload: request
    };
}

export async function fetchMockupsForCategoryServer(category,locale) {
    const res = await mockupsService.get(`collection/${category}?locale=${getLocaleCodeLong(locale)}`);
    return {
        type: FETCH_MOCKUPS_FOR_CATEGORY,
        payload: res
    };
}

export function setLastVisitedCollection(collection) {
    return {
        type: SET_LAST_VISITED_COLLECTION,
        payload: collection
    };
}

export function fetchMockupsForBundle(bundleId) {
    const request = serviceClient.get(`${BUNDLE_MOCKUPS_API}/mockups/${bundleId}`);
    return {
        type: FETCH_MOCKUPS_FOR_BUNDLE,
        payload: request
    };
}
